<template>
	<!-- nav -->
	<nav class="nav nav--fit">
		<div class="nav__wrap">
			<a class="nav__btn nav__btn--prev" @click="goBack()">
				<i class="icon icon icon-left">{{t('10030')}}</i>
			</a>
		</div>
	</nav>
	<!-- //nav -->
	<div id="container" class="container">
		<!-- [REST] 전체 내용 관리자 페이지에서 설정 -->
		<div id="sub" class="sub find">
			<!-- contact -->
			<section class="content">
				<div class="row">
					<div class="inner">
						<div class="inner__top">
							<h2 class="title">
								{{t('10229')}}
							</h2>
							<p class="inner__text">
								{{t('10234')}}
							</p>
						</div>
						<div class="inner__wrap">
							<div class="frms">
								<!-- 휴대폰 번호 -->
								<div class="frms__item">
									<div class="frms__wrap">
										<input type="email" class="ipt-frm" :placeholder="t('10780')" v-model="email"><!-- 재번역 체크 -->
										<div class="frms__btns">
											<button type="button" class="btn-frmreset" v-show="email.length > 0" @click="reset">
												<i class="icon icon-closesmall">{{t('10036')}}</i>
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- //content -->
		</div>
	</div>
	<div id="navigation" class="navigation navigation--noborder">
		<div class="btns btns--centered">
			<button type="button" class="btn " :class="isEmail ? 'btn-confirm' : 'btn-disabled'" @click="onClickEmailCheck">{{t('10119')}}</button>
		</div>
	</div>
	<ErrorAlert v-if="openErrorAlert" :type="alert_msg" :handleClose="handleCloseErrorAlert" :handleClick="handleClickAlert"/>
	<TheNavigation></TheNavigation>
</template>

<script>
import { useRouter } from 'vue-router';
import { ref, computed } from "vue"
import { useStore } from "vuex"
import ErrorAlert from "@/components/layers/Message_alert";
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 

export default {	
	components: {
		ErrorAlert,
	},
	setup() {
		const router = useRouter();
		const store = useStore();
		const openErrorAlert = ref(false)
		const userData = computed(() => store.state.userData);
		const email = ref("");
		const isEmail = computed(()=>email.value.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		));
		
		const onClickEmailCheck = () => {
			if(!isEmail.value){ return; }
			store.dispatch("mmb03/fetchMmb03", {
				"mem_id" : email.value
			}).then(data => {
				//console.log(data.data.Res_cd)
				if(data.data.Res_cd === "0000"){
					store.commit("SET_USERDATA", {userData: {}});
					router.push("/member/find/password/step2")
				}
				else {
					store.commit("app/SET_RESMESSAGE", {res_message: null});
					store.commit("app/SET_RESMESSAGE", {res_message: data.data.Res_msg});
					openErrorAlert.value = true;
					//alert(data.data.Res_msg)
				}
			});
		}
		
		const reset = () => {
			email.value = "";
		}

		const goBack = () => {
			router.go(-1);
		};
		const { t }= useI18n() //번역필수 모듈

		return {
			goBack,
			email,
			isEmail,
			userData,
			onClickEmailCheck,
			reset,			
			openErrorAlert,
			t,  //번역필수 모듈
		  i18n,
			handleCloseErrorAlert: () => {
				openErrorAlert.value = false;
			},
		}
	},
}
</script>
